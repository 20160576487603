import { useEffect } from 'react'
import { ILayout, useLayout } from '../../core'
import Newsletter from '../../../../components/Publication/Newsletter'
import HiringBanner from '../../../../components/Publication/HiringBanner'
import { Link } from 'react-router-dom'
import SubFooter from '../../../../components/Footer/SubFooter'

const PublicationFooter = () => {
  const { config } = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <>
      <div className="my-n3 pt-0">
        <div className="row d-flex justify-content-around bg-gray-800 mx-0 g-0">
          <div className="col-12 col-md-6 mb-5">
            <Newsletter />
          </div>
          <div className="col-12 col-md-6 mb-5">
            <HiringBanner />
          </div>
          <div className="col-12 col-xl-2 col-lg-4 fs-6 ps-5 ps-md-0">

            <h1 className="text-white">Who We Are</h1>
            <div className="d-flex flex-column">
              <Link to="/prime-advantage" className="text-white me-xl-5 me-md-5 ">History</Link>
              <Link to="/prime-advantage" className="text-white me-xl-5 me-md-5">Company Profile</Link>
              <Link to="/" className="text-white e">Capabiliy Statement</Link>
              <Link to="/leadership" className="text-white me-xl-5 me-md-5 ">Team PRIME</Link>
              <Link to="/" className="text-white ">Mission and Vision</Link>
              <Link to="/" className="text-white ">Core Values</Link>
            </div>
          </div>
          <div className="col-12 col-xl-2 col-lg-4 fs-6 ps-5 ps-md-0">
            <h1 className="text-white">What We Do</h1>
            <div className="d-flex flex-column">
              <Link to="/prime-advantage" className="text-white me-xl-5 me-md-5 ">Capital Markets and Investments</Link>
              <Link to="/our-expertise/seasoned" className="text-white me-xl-5 me-md-5 ">Commercial Retail Solutions</Link>
              <Link to="/our-expertise/accelerate" className="text-white ">Corporate Office Solutions</Link>
              <Link to="/our-expertise/seasoned" className="text-white me-xl-5 me-md-5">Research and Advisory</Link>
            </div>
          </div>

          <div className="col-12 col-xl-2 col-lg-4 fs-6 ps-5 ps-md-0">
            <h1 className="text-white">Metro Manila</h1>
            <div className="d-flex flex-column text-white">
              <p>Ground Floor, Pacific Century Tower #1472 - 1476, Quezon Avenue, South Triangle, Quezon City, Metro Manila, Philippines 1103</p>
              <span><i className="fa-solid fa-phone text-white"></i> (+632) 8442 8888</span><br />
              <span><i className="fa-solid fa-mobile-screen-button text-white"></i> (+63) 917 555 8222</span><br />
              <span><i className="fa-solid fa-envelope text-white"></i> inquiry@primephilippines.com</span>
            </div>
          </div>

          <div className="col-12 col-xl-2 col-lg-4 fs-6 ps-5 ps-md-0" >
            <h1 className="text-white">Mindanao</h1>
            <div className="d-flex flex-column text-white">
              <p>Fourth Floor, Topaz Tower,Damosa I.T. Park, J.P. Laurel Avenue, Lanang,Davao City, Davao Del Sur, Philippines 8000</p>
              <span><i className="fa-solid fa-phone text-white"></i> (+6382) 238 7576</span><br />
              <span><i className="fa-solid fa-mobile-screen-button text-white"></i> (+63) 917 893 3995/(+63) 917 818 2699</span><br />
              <span><i className="fa-solid fa-envelope text-white"></i> inquiry.davao@primephilippines.com</span>
            </div>
          </div>

          <div className="col-12 col-xl-2 col-lg-4 fs-6 ps-5 ps-md-0">
            <h1 className="text-white">Visayas</h1>
            <div className="d-flex flex-column text-white">
              <p>Fifth Floor, Park Centrale, Cebu I.T. Park Jose Maria del Mar Street, Cebu City 6000 Cebu</p>
              <span><i className="fa-solid fa-phone text-white"></i> (+6332) 230 4930</span><br />
              <span><i className="fa-solid fa-mobile-screen-button text-white"></i> (+63) 917 843 6134</span><br />
              <span><i className="fa-solid fa-envelope text-white"></i> inquiry.cebu@primephilippines.com</span>
            </div>
          </div>

          <SubFooter />
        </div>

      </div>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export { PublicationFooter }
