import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../helpers';
import { useLayout } from '../../core';
import { Navbar } from './Navbar';
import Subheader from './Subheader';
import { useEffect } from 'react';
import { DrawerComponent } from '../../../assets/ts/components';

export function HeaderWrapper() {
  const { config, classes } = useLayout();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/homepage') {
      DrawerComponent.hideAll();
    }
  }, [location.pathname]);

  if (!config.app?.header?.display) {
    return null;
  }

  return (
    <div className="sticky-top">
      <div id='kt_app_header' className='app-header h-lg-100px h-70px'>
        <div
          id='kt_app_header_container'
          className={clsx(
            'mx-lg-5 w-100 justify-content-between px-10',
            classes.headerContainer.join(' '),
            config.app?.header?.default?.containerClass
          )}
        >
          <div className="pt-3 flex-lg-grow-1">
            <Link to="/" className="navbar-brand">
              <img
                loading="lazy"
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/prime-logo.webp')}
                className='h-lg-80px h-50px app-sidebar-logo-default'
              />
            </Link>
          </div>
          <div
            id='kt_app_header_wrapper'
            className='d-flex align-items-stretch justify-content-between'
          >
            <Navbar />
          </div>
        </div>
      </div>
      <Subheader />
    </div>
  );
}
