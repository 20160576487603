import { Link } from "react-router-dom";

export default function JobCard(content: { link: string, image: string, level: string, title: string }) {
	return (
		<Link to={content.link} aria-label={content.title}>
			<div className="card h-100">
				<img loading="lazy" src={content.image} className="img-fluid object-fit-cover" alt={content.title} />
				<div className="card-img-overlay bg-dark opacity-67" ></div>
				<div className="card-img-overlay text-white text-center justify-content-center d-flex flex-column">
					<p className="fs-5">{content.level}</p>
					<p className="h3 text-white">{content.title}</p>
				</div>
			</div>
		</Link>
	)
}