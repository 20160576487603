import {
    BiSolidPhoneCall,
} from 'react-icons/bi'

import {
    MdOutlineSupportAgent
} from 'react-icons/md'
import { useEffect, useRef, useState } from 'react'
import anime from 'animejs'
import { useDispatch, useSelector } from 'react-redux'

const FloatingAssistant = ({ reference }: any) => {
    const [toggleCard, setToggleCard] = useState<boolean>(false)
    const [toggleClipboard, setToggleClipboard] = useState<boolean>(false)

    const clipboardRef = useRef<any>()
    const cardRef = useRef<any>()

    const dispatch = useDispatch()
    const { callback } = useSelector((state: any) => state.floatingAssistant)

    useEffect(() => {
        if (toggleClipboard) {
            setTimeout(() => {
                setToggleClipboard(!toggleClipboard)
            }, 500)
        }
    }, [toggleClipboard])

    const handleClipboard = async () => {
        navigator.clipboard.writeText("+639175558222")
        anime({
            targets: clipboardRef.current,
            opacity: toggleClipboard ? [1, 0] : [0, 1],
            duration: 500
        })
        setToggleClipboard(!toggleClipboard)
    }
    useEffect(() => {
        const shakeAnim = anime({
            targets: "#talk-to-an-expert",
            rotate: ['-10deg', '10deg', '-10deg'],
            duration: 600,
            loop: 3,
            easing: 'easeInOutSine',
            complete: () => {
                setTimeout(() => {
                    shakeAnim.restart()
                }, 1000)
            }
        });
    }, [])
    const handleToggleCard = () => {
        anime({
            targets: cardRef.current,
            opacity: toggleCard ? [1, 0] : [0, 1],
            duration: 500,
            easing: 'linear'
        })
        setToggleCard(!toggleCard)
    }
    const handleCallBack = () => {
        anime({
            targets: reference.current,
            opacity: 1,
            duration: 500,
            easing: 'linear'
        })
        dispatch({ type: 'floatingAssistant/toggleCallback', payload: !callback })
    }
    const handleDial = () => {
        window.location.href = `tel:+639175558222`
    }
    return (
        <>
            <div className="w-0 d-flex flex-end col-md-7 col-sm-8 position-fixed gap-2 px-2" style={{
                zIndex: 100,
                bottom: '5%',
                right: '3%'
            }}>
                <div className="w-0 d-flex flex-end col-md-7 col-sm-8 gap-2 flex-wrap">
                    <div ref={cardRef} className={`w-100 card rounded-2 p-2 border border-primary ${toggleCard ? 'opacity-1 d-block' : 'opacity-0 d-none'}`}>
                        <div className="px-2 fs-1 fw-semibold text-primary">Talk to an Expert</div>
                        <p className='p-2'>We have a team of specialists available 24/7 to help you find the best solution for your business. </p>
                        <div className="p-1 fs-1 d-flex align-items-center gap-1 position-relative">
                            <span className='p-2 cursor-pointer' style={{ userSelect: 'none', cursor: 'pointer' }} onClick={handleDial}>Call +639175558222</span>
                        </div>
                        <div className="p-2 bg-white border border-1 border-primary rounded cursor-pointer" onClick={handleCallBack}>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-start gap-2">
                                    <MdOutlineSupportAgent
                                        size={30}
                                    />
                                    <div className="d-flex flex-column align-items-start justify-content-center">
                                        <span>Request a call back</span>
                                        <small style={{ fontSize: '10px' }} className='text-primary'>Call backs typically happen in a few minutes or less</small>
                                    </div>
                                </div>
                                <div style={{ fontSize: 32 }}>&rsaquo;</div>
                            </div>
                        </div>
                        <hr className='mx-2' />
                        <div className="p-2">
                            <div className="fs-4">Talk to customer service</div>
                            <small>speak with a customer service agent, please raise a ticket here.</small>
                            <br />
                            <small className="text-danger cursor-pointer">Contact customer support team &rsaquo;</small>
                        </div>
                    </div>
                    <BiSolidPhoneCall
                        id='talk-to-an-expert'
                        className='text-secondary bg-primary rounded-circle p-2 border border-secondary'
                        cursor={"pointer"}
                        width={30}
                        height={30}
                        size={50}
                        onClick={handleToggleCard}
                    />
                </div>
            </div>
        </>
    )
}
export { FloatingAssistant }