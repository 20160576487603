import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { useState, useRef } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha';

export function PublicationHeaderWrapper() {
  const site_key = process.env.REACT_APP_RECAPTCHA_KEY;
  const [fullname, setFullname] = useState<any>(null)
  const [email, setEmail] = useState<any>(null);
  const [phone, setPhone] = useState<any>(null);
  const [company, setCompany] = useState<any>(null);
  const [message, setMessage] = useState<any>(null);
  const captchaRef = useRef(null);
  const dataToSend = new FormData();
  const url = process.env.REACT_APP_PRIMECORE_API_URL + "save_inquiry/" + process.env.REACT_APP_PRIMECORE_USER + "/" + process.env.REACT_APP_PRIMECORE_PASSWORD
  const siteURL = useLocation();

  const [colorChange, setColorchange] = useState(false);

  const submit = () => {
    var nameValid = false;
    var emailValid = false;
    var numberValid = false;
    var companyValid = false;
    var valid = false;

    if (!(document.getElementById("nameInput")! as HTMLInputElement).checkValidity()) {
      nameValid = false;
      document.getElementById("nameInput")!.classList.remove("is-valid");
      document.getElementById("nameInput")!.classList.add("is-invalid");
    }
    else {
      nameValid = true;
      document.getElementById("nameInput")!.classList.remove("is-invalid");
      document.getElementById("nameInput")!.classList.add("is-valid");
    }
    if (!(document.getElementById("emailInput")! as HTMLInputElement).checkValidity()) {
      emailValid = false;
      document.getElementById("emailInput")!.classList.remove("is-valid");
      document.getElementById("emailInput")!.classList.add("is-invalid");
    }
    else {
      emailValid = true;
      document.getElementById("emailInput")!.classList.remove("is-invalid");
      document.getElementById("emailInput")!.classList.add("is-valid");
    }

    if (!(document.getElementById("numberInput")! as HTMLInputElement).checkValidity()) {
      numberValid = false;
      document.getElementById("numberInput")!.classList.remove("is-valid");
      document.getElementById("numberInput")!.classList.add("is-invalid");
    }
    else {
      numberValid = true;
      document.getElementById("numberInput")!.classList.remove("is-invalid");
      document.getElementById("numberInput")!.classList.add("is-valid");
    }

    if (!(document.getElementById("companyInput")! as HTMLInputElement).checkValidity()) {
      companyValid = false;
      document.getElementById("companyInput")!.classList.remove("is-valid");
      document.getElementById("companyInput")!.classList.add("is-invalid");
    }
    else {
      companyValid = true;
      document.getElementById("companyInput")!.classList.remove("is-invalid");
      document.getElementById("companyInput")!.classList.add("is-valid");
    }

    if (nameValid == true && emailValid == true && numberValid == true && companyValid == true) {
      valid = true;
    }

    if (valid == true) {
      if (!captchaRef.current?.getValue()) {
        alert('Please verify recaptcha')
      }
      else {
        dataToSend.append('email', email);
        dataToSend.append('fullname', fullname);
        dataToSend.append('phone', phone);
        dataToSend.append('url', siteURL.pathname);
        dataToSend.append('company', company)
        dataToSend.append('message', message)
        axios.post(url + "", dataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data; ',
          }
        }).then((response) => {
          console.log(response);
          captchaRef.current?.reset()
        }).catch((error) => {
          if (error.response) {
            console.log(error.response);
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        })
      }
    }
    else {

    }

  }


  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y < -5) {
        setColorchange(true);

      }
      else {
        setColorchange(false);

      }
    })


  const { config, classes } = useLayout()
  if (!config.app?.header?.display) {
    return null
  }
  return (
    <>
      <div className="sticky-top">
        <div id='kt_app_header' className={colorChange ? 'app-header bgWhiteTransition shadow' : 'app-header bgTransparentTransition'}>
          <div
            id='kt_app_header_container'
            className={clsx(
              'mx-lg-5 w-100 justify-content-between',
              classes.headerContainer.join(' '),
              config.app?.header?.default?.containerClass
            )}
          >
            <div className="pt-3 flex-lg-grow-1 ">
              <div className="mt-n5 ms-n5 h-67px w-200px bg-white">
                <Link to="/homepage" className="navbar-brand">
                  <img loading="lazy"
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/prime-logo.webp')}
                    className='mt-5 ms-5 h-50px app-sidebar-logo-default'
                  />
                </Link>
              </div>
            </div>


            <div
              id='kt_app_header_wrapper'
              className='d-flex align-items-stretch justify-content-between bg-transparent '
            >
              <nav className='navbar navbar-expand-md'>
                <div className="container-fluid bg-transparent">

                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <div className='nav-item ms-10'>
                      <Link className={colorChange ? 'uppercase fs-3 text-black' : 'uppercase fs-3 text-white'} to="/prime-advantage">About Prime</Link>
                    </div>

                    <div className='app-navbar-item ms-10 me-10'>
                      <Link className={colorChange ? 'uppercase fs-3 text-black' : 'uppercase fs-3 text-white'} to="/our-expertise/accelerate/research-advisory">Other Services</Link>
                    </div>

                    <div className='app-navbar-item mt-3'>
                      <button className="btn h4 text-black bg-secondary uppercase p-5" data-bs-toggle="modal" data-bs-target="#consultModal">Consult With Us</button>
                    </div>

                  </div>
                </div>
              </nav>

              <div className='mt-3'>
                <button className="d-inline d-md-none btn h4 text-black bg-secondary uppercase p-5" data-bs-toggle="modal" data-bs-target="#consultModal">Consult Us</button>
              </div>

            </div>



          </div>

        </div>
      </div>

      <div className="modal fade" id="consultModal" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-between">
                <h1 className="flex-grow text-primary uppercase">Consult with us today</h1>
                <button type="button" className="fs-1 flex-shrink btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>

              {/*<Formik
              initialValues={{name: "", email: "", contactNum: "", company: ""}}
              onSubmit={async (values) => {
                  await new Promise((resolve) => setTimeout(resolve, 500));
                  alert(JSON.stringify(values, null, 2));
                }}
            >*/}
              <form>
                <label className="uppercase" htmlFor="name">Full Name</label>
                <input required className="form-control" type="text" id="nameInput" onChange={(e) => setFullname(e.target.value)} />

                <label className="uppercase" htmlFor="company">Company</label>
                <input required className="form-control" type="text" id="companyInput" onChange={(e) => setCompany(e.target.value)} />

                <label className="uppercase" htmlFor="email">Email Address</label>
                <input required className="form-control" type="email" id="emailInput" onChange={(e) => setEmail(e.target.value)} />

                <label className="uppercase" htmlFor="contactNum">Contact Number</label>
                <input required className="form-control" type="number" id="numberInput" onChange={(e) => setPhone(e.target.value)} />

                <label className="uppercase" htmlFor="message">PLEASE DESCRIBE YOUR SERVICE REQUIREMENT </label>
                <textarea className="form-control" id="messageInput" onChange={(e) => setMessage(e.target.value)} />

                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey={site_key!}
                  className="pt-5"
                />

                <button type="button" onClick={submit} className="btn border border-2 mt-5">Submit</button>
              </form>
              {/*</Formik>*/}


            </div>
          </div>
        </div>
      </div>
    </>
  )
}
