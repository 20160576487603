import {Link} from 'react-router-dom'

export default function HiringBanner() {
	return(
	<div className="card overflow-hidden h-100">
		<img loading="lazy" className="card-img-top object-cover h-150px" src="https://d33wubrfki0l68.cloudfront.net/4ccac8dd1276e3777ad9da9213385e718d0b930b/9d118/static/hiring-32c472b686b6595f63149bbe67fc63d4.jpg" alt="Image" />
		<div className="card-img-overlay bg-primary opacity-50"/>
		<div className="card-img-overlay d-flex flex-column text-center align-items-center">
			<h1 className="text-secondary uppercase">We are hiring</h1>
			<p className="text-white">Enhance your career with a company that invests in you, Experience the different culture we have.</p>
			
			<Link to="/careers"><button type="submit" className="col-12 btn btn-secondary">Take Me There</button></Link>
			
		</div>
	</div>
	)
}