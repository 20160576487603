export default function Newsletter() {

	return(
	<div className="card overflow-hidden h-150px bg-primary">
			<div className=" d-flex justify-content-center bg-primary p-5 px-sm-20">
				
				<form className="w-100 text-start">
					<div className="row">
						<h1 className=" text-white ">Subscribe to our newsletter</h1>
						<p className="text-white">Get the latest real estate insights, events and news from our newsletter</p>
					</div>
					<div className="row">
						
					</div>
					<div className="row">
						<div className="col-9">
							<label>
								<input required type="email" name="email" className="form-control" id="radarInputEmail" placeholder="Input your email address"/>
							</label>
						</div>
						<div className="col-2">
							<button type="submit" className="btn btn-secondary">Subscribe</button>
						</div>
					</div>
				</form>
					
			</div>
		</div>
	)
}