import {Link} from 'react-router-dom'

export default function SubFooter() {
	return(
	<div className="fs-6 m-1 p-3 mt-5 bg-black text-white d-flex justify-content-between">
		<span>© 2013 - 2023 PRIME Philippines. All Rights Reserved.</span>

		<span className="text-decoration-underline"><Link to="/legal/privacy-policy" className="text-white me-5">Privacy Policy</Link> <Link to="/legal/terms-and-conditions" className="text-white">Terms and Conditions</Link></span>
	</div>
	)
}