import { configureStore } from '@reduxjs/toolkit'
import HomeReducer from './reducers/HomeReducer'
import CareerReducer from './reducers/CareerReducer'
import PropertyReducer from './reducers/PropertyReducer'
import PublicationReducer from './reducers/PublicationReducer'
import FloatingAssistantReducer from './reducers/FloatingAssistantReducer'
const Store = configureStore({
    reducer: {
        home: HomeReducer.reducer,
        career: CareerReducer.reducer,
        property: PropertyReducer.reducer,
        publication: PublicationReducer.reducer,
        floatingAssistant: FloatingAssistantReducer.reducer
    }
})
export default Store