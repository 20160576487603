import { createSlice } from '@reduxjs/toolkit'



const FloatingAssistantReducer = createSlice({
    name: 'floatingAssistant',
    initialState: {
        callback: false
    },
    reducers: {
        toggleCallback: (state, { payload }) => {
            state.callback = payload
        }
    }
})
export default FloatingAssistantReducer