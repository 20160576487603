import { useState, useEffect } from 'react';
import ExpertiseNavCard from './ExpertiseNavCard';
import NavList from './NavList';
import axios from 'axios';

const { REACT_APP_API_URL, REACT_APP_EVENT_API_URL } = process.env;

export default function ExpertiseNav({closeDrawer} :any) {
	const url = process.env.REACT_APP_API_URL;

	const [servicesList, setServicesList] = useState(null);

	useEffect(() => {
		axios.get(url + "services", {
			params: {
				'per_page': 11,
				order: 'asc',
			}
		}).then((response) => {
			setServicesList(response.data);
		})
	}, [])

	const testServicesList = [
		{
			name: "End to End real estate services",
			link: "our-expertise/experienced/end-to-end-real-estate-services"
		},
		{
			name: "Research & Advisory",
			link: "our-expertise/experienced/research-and-advisory"
		},
		{
			name: "Capital Markets & Investments",
			link: "our-expertise/experienced/property-acquisition-and-disposal-made-easy"
		},
		{
			name: "Developer and Landlord brokerage Services",
			link: "our-expertise/experienced/developer-and-landlord-brokerage-services"
		},
		{
			name: "Tenant and Occupier Brokerage Services",
			link: "our-expertise/experienced/tenant-and-occupier-brokerage-services"
		},
		{
			name: "Hotel & Leisure Services",
			link: "our-expertise/experienced/hotel-leisure-services"
		},
		{
			name: "Real Estate Documentation Services",
			link: "our-expertise/experienced/real-estate-documentation-services"
		},
		{
			name: "Design & Construction Services",
			link: "our-expertise/experienced/design-construction-services"
		},
		{
			name: "Asset & Property Management",
			link: "our-expertise/experienced/asset-property-management"
		},
		{
			name: "Valuation and Appraisal Services",
			link: "our-expertise/experienced/valuation-and-appraisal-services"
		},
		{
			name: "Business Support and Enabler Services (GW Solutions)",
			link: "our-expertise/experienced/business-support-and-enabler-services-gw-solutions"
		},
	]

	if (servicesList == null)
		return null;

	return (

		<div className="container">
			{/*<div className="row d-flex align-items-center pt-3">
    				<div className="col-12 col-lg-3 col-md-12">
	    				<h1>Our Expertise</h1>
	    			</div>
	    			<div className="col-9 ">
	    				<p>Whatever your real estate service needs may be, our expert team of dedicated professionals will deliver value beyond your expectations.</p>
	    			</div>
	    		</div>
	    	*/}
			<div className="row d-flex flex-row pt-5 shadow-sm">
				<div className="col-12 col-lg-7 col-md-12 d-inline-flex flex-column justify-content-between row-cols-1">
					<ExpertiseNavCard
						style={{ height: '10rem' }}
						image="https://primephilippines.com/static/hero-image-5305f2394fcd0010ca43a466cf633034.png"
						body="Designed to provide utmost ease and convenience to newcomers in real estate"
						title="KICKSTART"
						bodyTextColor=""
						taglineText="Starting out in real estate?"
						titleTextClass="text-primary fw-bolder"
						link="/our-expertise/kickstart"
						bgColor="bg-primary"
						linkStyle="mt-n5 mt-lg-0"
						closeDrawer={closeDrawer}
					/>
					<ExpertiseNavCard
						style={{ height: '10rem' }}
						image="https://primephilippines.com/static/hero-image-5305f2394fcd0010ca43a466cf633034.png"
						body="For those who are new to the industry, let us help you hit the ground running."
						title="ACCELERATE"
						bodyTextColor=""
						taglineText="Need to pick up the pace?"
						titleTextClass="text-primary fw-bolder"
						link="/our-expertise/accelerate"
						bgColor="bg-primary"
						linkStyle="mt-n5"
						closeDrawer={closeDrawer}
					/>
					<ExpertiseNavCard
						style={{ height: '10rem' }}
						image="https://primephilippines.com/static/hero-image-5305f2394fcd0010ca43a466cf633034.png"
						body="Wherever you are in the industry, we can help you find your breakthrough with our tailored-fit solutions."
						title="SEASONED"
						bodyTextColor=""
						taglineText="In need of a breakthrough?"
						titleTextClass="text-primary fw-bolder"
						link="/our-expertise/seasoned"
						bgColor="bg-primary"
						linkStyle="mt-n5"
						closeDrawer={closeDrawer}
					/>

				</div>

				<div className="col-12 col-md-5">

					<NavList
						from="our-expertise/accelerate/"
						object={servicesList}
					/>

				</div>
			</div>
		</div>

	)
}