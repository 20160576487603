import JobCard from './JobCard'

export default function JobGrid(content: any) {
	return (
		<div className="row g-2">
			{content.object.map((content: any, index: number) => (
				<div className="col-6 col-sm-6 col-md-4 col-lg-3" key={"job" + index}>
					<JobCard
						image={content._embedded["wp:featuredmedia"][0].source_url}
						level={content._embedded["wp:term"][1][0].name}
						title={content.title.rendered}
						link={"/careers/" + content.slug}
					/>
				</div>
			))}
		</div>
	)
}