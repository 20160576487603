import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    OfficeList: [],
    Districts: [],
    CommList: [],
    IndusList: [],
}

const PropertyReducer = createSlice({
    name: 'property',
    initialState,
    reducers: {
        setOfficeList: (state, { payload }) => {
            state.OfficeList = payload
        },
        setCommList: (state, { payload }) => {
            state.CommList = payload
        },
        setIndusList: (state, { payload }) => {
            state.IndusList = payload
        },
        setDistricts: (state, { payload }) => {
            state.Districts = payload
        }
    }
})

export default PropertyReducer