import {useEffect, useState} from 'react';
import Card from '../../components/Card'
import NavList from '../../components/NavbarCustom/NavList'
import axios from 'axios';

export default function InsightsNav({closeDrawer} : any) {

	const url = process.env.REACT_APP_API_URL;
	const [insights, setInsights] = useState<any>(null);

	const [insightsCategories, setInsightsCategories] = useState<any>(null);

	useEffect(() => {
		axios.get(url+"insights", {
			params: {
				per_page: 2,
				"_embed": "wp:term,author"
			}
		}).then((response) => {
			setInsights(response.data);
		})

		axios.get(url+"categories", {
			params: {
				per_page: 11,

			}
		}).then((response) => {
			setInsightsCategories(response.data)
		})
	}, [])
	if (insights == null || insightsCategories == null)
		return null;

	return(
		<div className="p-2 shadow-sm">
    		<div className="row d-flex flex-row ms-1 mt-1">
    			<div className="col-12 col-md-5 mt-5 order-2">

	    			<NavList
	    				from="/insights/"
	    				title="Industry Insights"
	    				object={insightsCategories}
						closeDrawer={closeDrawer}
    				/>

	    		</div>

	    		<div className="order-1 col-12 col-lg-7 d-inline-flex flex-column justify-content-between ">
		    		<Card 
                        class=""
                        bgColor="bg-primary"
                        titleTextColor="text-secondary fs-3"
						subtitleTextColor="text-grey-700"
                        bodyClass="text-white fs-6 text-truncate-ten"
                        title={insights[0].title.rendered}
                        date={new Date(insights[0].date).toDateString().split(' ').slice(1).join(' ')}
                        author={insights[0]._embedded.author.name}
                        body={insights[0].content.rendered}
                        link={"/insights/article/"+insights[0].slug}
                    />
                    
	    		</div>
	    	</div>
	    </div>
	    
	)
}