import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    OfficeList: [],
    CommList: [],
    IndusList: [],
    RetailList: [],
    LotList: [],
    MetaTags: {},
    HeaderVideo: null,
    Insights: [],
    Publications: [],
    Districts: [],
    ClientLists : []
}

const HomeReducer = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setOfficeList: (state, { payload }) => {
            state.OfficeList = payload
        },
        setCommList: (state, { payload }) => {
            state.CommList = payload
        },
        setIndusList: (state, { payload }) => {
            state.IndusList = payload
        },
        setRetailList: (state, { payload }) => {
            state.RetailList = payload
        },
        setLotList: (state, { payload }) => {
            state.LotList = payload
        },
        setMetaTags: (state, { payload }) => {
            state.MetaTags = payload
        },
        setHeaderVideo: (state, { payload }) => {
            state.HeaderVideo = payload
        },
        setInsights: (state, { payload }) => {
            state.Insights = payload
        },
        setPublications: (state, { payload }) => {
            state.Publications = payload
        },
        setDistricts: (state, { payload }) => {
            state.Districts = payload
        },
        setClientLists : (state, {payload}) => {
            state.ClientLists = payload
        }
    }
})

export default HomeReducer
export const {
    setOfficeList,
    setCommList,
    setIndusList,
    setRetailList,
    setLotList,
    setMetaTags,
    setHeaderVideo,
    setInsights,
    setPublications,
    setDistricts
} = HomeReducer.actions