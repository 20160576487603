import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    JobLevel: null,
    Internships: [],
    JobPostings: [],
    RecruitVideo: null
}

const CareerReducer = createSlice({
    name: 'career',
    initialState,
    reducers: {
        setJobLevel: (state, { payload }) => {
            state.JobLevel = payload
        },
        setInternships: (state, { payload }) => {
            state.Internships = payload
        },
        setJobPostings: (state, { payload }) => {
            state.JobPostings = payload
        },
        setRecruits: (state, { payload }) => {
            state.RecruitVideo = payload
        },
    }
})

export default CareerReducer