import { DrawerComponent } from '../../../assets/ts/components';
import Offcanvas from './Offcanvas';
import { useEffect } from 'react'
const Navbar = () => {
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [])
  return (
    <>
      <button className="btn me-n10 d-block d-lg-none d-xl-none d-md-inline d-sm-inline" data-kt-drawer-show="true" id="kt_drawer" aria-label="Open Drawer">
        <i className="fa-solid fa-bars text-primary" style={{fontSize: '30px'}} />
      </button>

      <div
        id="kt_drawer_nav"
        className="bg-white mt-20"
        data-kt-drawer="true"
        data-kt-drawer-activate="true"
        data-kt-drawer-toggle="#kt_drawer"
        data-kt-drawer-width="100%"
      >
        <Offcanvas />
      </div>
      <div className="d-lg-block d-none mt-3">
        <div className="row">
          <div className="col-10 text-primary fs-2 text-nowrap text-end">
            <b>REAL ESTATE.</b><br />
            WE <b>ADVISE.</b><br />
            YOU <b>ADVANCE.</b>
          </div>
          <div className="col-2">
            <img loading="lazy"
              src="/media/divisors/realestatewead-bar.webp"
              className="h-80px"
              alt="Navbar"
            />
          </div>
        </div>
      </div>

      <nav className='navbar navbar-expand-lg'>
        <div className="container-fluid bg-white">
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
          </div>
        </div>
      </nav>
    </>
  )
}

export { Navbar }
