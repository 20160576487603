import { Suspense, lazy } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { PublicationLayout } from '../../_metronic/layout/PublicationLayout'
import JobOpenings from '../pages/JobOpenings'
// import HomePage from '../pages/HomePage'
// import DistrictPage from '../pages/DistrictPage'
// import StarterPage from '../pages/Expertise/StarterPage'
// import SeasonedPage from '../pages/Expertise/SeasonedPage'
// import ExperiencedPage from '../pages/Expertise/ExperiencedPage'
// import ExpertiseDetail from '../pages/Expertise/ExpertiseDetail'
// import InsightsLanding from '../pages/Insights/InsightsLanding'
// import InsightsDisplay from '../pages/Insights/InsightsDisplay'
// import InsightsArticle from '../pages/Insights/InsightsArticle'
// import SearchLanding from '../pages/Search/SearchLanding'
// import SearchPage from '../pages/Search/SearchPage'
// import PropertyDetail from '../pages/Search/PropertyDetail'
// import PropertyDetailRev from '../pages/Search/PropertyDetailRev'
// import ContactUs from '../pages/ContactUs'
// import Leadership from '../pages/About/Leadership'
// import PrimeAdvantage from '../pages/About/PrimeAdvantage'
// import Awards from '../pages/About/Awards'
// import ResponsibilityLanding from "../pages/About/ResponsibilityLanding"
// import CityEnabling from "../pages/About/CityEnabling"
// import CorporateResponsibility from "../pages/About/CorporateResponsibility"
// import CareersPage from '../pages/About/CareersPage'
// import PressRoom from '../pages/About/PressRoom'
// import NewsArticle from '../pages/PressRoom/NewsArticle'
// import JobPage from '../pages/Careers/JobPage'
// import SimplePage from '../pages/About/SimplePage'
// import PublicationArticle from '../pages/Publication/PublicationArticle'
// import EventPage from '../pages/EventPage'
// import SplashScreen from '../../components/SplashScreen'
// import PublicationPage from '../pages/Publication/PublicationPage'


const HomePage = lazy(() => import('../pages/HomePage'))
const DistrictPage = lazy(() => import('../pages/DistrictPage'))
const StarterPage = lazy(() => import('../pages/Expertise/StarterPage'))
const SeasonedPage = lazy(() => import('../pages/Expertise/SeasonedPage'))
const ExperiencedPage = lazy(() => import('../pages/Expertise/ExperiencedPage'))
const ExpertiseDetail = lazy(() => import('../pages/Expertise/ExpertiseDetail'))
const InsightsLanding = lazy(() => import('../pages/Insights/InsightsLanding'))
const InsightsDisplay = lazy(() => import('../pages/Insights/InsightsDisplay'))
const InsightsArticle = lazy(() => import('../pages/Insights/InsightsArticle'))
const SearchLanding = lazy(() => import('../pages/Search/SearchLanding'))
const SearchPage = lazy(() => import('../pages/Search/SearchPage'))
const PropertyDetail = lazy(() => import('../pages/Search/PropertyDetail'))
const PropertyDetailRev = lazy(() => import('../pages/Search/PropertyDetailRev'))
const ContactUs = lazy(() => import('../pages/ContactUs'))
const Leadership = lazy(() => import('../pages/About/Leadership'))
const PrimeAdvantage = lazy(() => import('../pages/About/PrimeAdvantage'))
const Awards = lazy(() => import('../pages/About/Awards'))
const ResponsibilityLanding = lazy(() => import("../pages/About/ResponsibilityLanding"))
const CityEnabling = lazy(() => import("../pages/About/CityEnabling"))
const CorporateResponsibility = lazy(() => import("../pages/About/CorporateResponsibility"))
const CareersPage = lazy(() => import('../pages/About/CareersPage'))
const PressRoom = lazy(() => import('../pages/About/PressRoom'))
const NewsArticle = lazy(() => import('../pages/PressRoom/NewsArticle'))
const JobPage = lazy(() => import('../pages/Careers/JobPage'))
const SimplePage = lazy(() => import('../pages/About/SimplePage'))
const PublicationArticle = lazy(() => import('../pages/Publication/PublicationArticle'))
const EventPage = lazy(() => import('../pages/EventPage'))
const PublicationpPage = lazy(() => import('../pages/Publication/PublicationPage'))
const SplashScreen = lazy(() => import('../../components/SplashScreen'))
const HomePage2 = lazy(() => import('../pages/Homepage2'))
const HomePage3 = lazy(() => import('../pages/Homepage3'))

const PrivateRoutes = () => {

  const routes = [
    {
      path: '/',
      Component: HomePage3,
    },
    {
      path: '/homepage/2',
      Component: HomePage2,
    },
    {
      path: '/homepage/3',
      Component: HomePage,
    },
    {
      path: '/business-districts/:slug',
      Component: DistrictPage,
    },
    {
      path: '/our-expertise/kickstart',
      Component: StarterPage,
    },
    {
      path: '/our-expertise/seasoned',
      Component: SeasonedPage,
    },
    {
      path: '/our-expertise/accelerate',
      Component: ExperiencedPage,
    },
    {
      path: '/our-expertise/accelerate/:slug',
      Component: ExpertiseDetail,
    },
    {
      path: '/insights/article/:slug',
      Component: InsightsArticle,
    },
    {
      path: '/find-a-property',
      Component: SearchLanding,
    },
    {
      path: '/find-a-property/search',
      Component: SearchPage,
    },
    {
      path: '/properties/:slug',
      Component: PropertyDetail,
    },
    {
      path: '/listings/:slug',
      Component: PropertyDetailRev,
    },
    {
      path: '/contact-us',
      Component: ContactUs,
    },
    {
      path: '/leadership',
      Component: Leadership,
    },
    {
      path: '/prime-advantage',
      Component: PrimeAdvantage,
    },
    {
      path: '/awards-and-recognition',
      Component: Awards,
    },
    {
      path: '/careers',
      Component: CareersPage,
    },
    {
      path: '/careers/:slug',
      Component: JobPage,
    },
    {
      path: '/job-openings',
      Component: JobOpenings,
    },
    {
      path: '/press-room',
      Component: PressRoom,
    },
    {
      path: '/press-room/:slug',
      Component: NewsArticle,
    },
    {
      path: '/events',
      Component: EventPage,
    },
    {
      path: '/legal/:slug',
      Component: SimplePage,
    }
  ]
  return (
    <Suspense fallback={<SplashScreen />}>
      <Routes>
        <Route element={<MasterLayout />}>
          {
            routes.map((Router, key) => (
              <Route
                key={key}
                path={Router.path}
                element={
                  <Suspense fallback={<span>Loading component...</span>}>
                    <Router.Component />
                  </Suspense>
                }
              />
            ))
          }
          <Route path="/insights" element={<Suspense fallback={<span>Loading component...</span>}><InsightsLanding /></Suspense>}>
            <Route path=":slug" element={<Suspense fallback={<span>Loading component...</span>}><InsightsDisplay /></Suspense>} />
          </Route>
          <Route element={<ResponsibilityLanding />}>
            <Route path="/city-enabling" element={<Suspense fallback={<span>Loading component...</span>}><CityEnabling /></Suspense>} />
            <Route path="/corporate-social-responsibility" element={<Suspense fallback={<span>Loading component...</span>}><CorporateResponsibility /></Suspense>} />
          </Route>
        </Route>
        <Route element={<PublicationLayout />}>
          <Route path="/publications" element={<Suspense fallback={<span>Loading component...</span>}><PublicationpPage /></Suspense>} />
          <Route path="/publications/:slug" element={<Suspense fallback={<span>Loading component...</span>}><PublicationArticle /></Suspense>} />
        </Route>
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </Suspense>
  )
}
export { PrivateRoutes }
