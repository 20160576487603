import { useState } from 'react';

export default function RadarFooterBanner() {
	const mailURL = process.env.REACT_APP_MAIL_URL;
	const [radarSubscribeEmail, setRadarSubscribeEmail] = useState("");
	return (
		<div className="card overflow-hidden" style={{ height: '200px' }}>
			<img loading="lazy" src="https://s3.ap-southeast-1.amazonaws.com/assets.primephilippines.com/media/banners/640_primeradar-2.webp" className="img-fluid h-100 d-sm-none d-none d-md-flex" alt="PRIME Radar" />
			<img loading="lazy" src="https://s3.ap-southeast-1.amazonaws.com/assets.primephilippines.com/media/banners/radar-cover-image-a71dd0739befd23a0007fcfdcfb4cf62.webp" className="img-fluid d-md-none d-xs-flex" alt="PRIME Radar" />
			<div className={"card-img-overlay bg-dark bg-gradient opacity-75 w-100"}></div>
			<div className="card-img-overlay d-flex justify-content-center">
				<div className="row">
					<div className="col-12 col-md-5 d-flex align-items-center">
						<img
							loading="lazy"
							src="/media/logos/prime-radar-white.webp"
							className="banner-image"
							alt="PRIME Radar"
						/>
					</div>
					<div className="col-12 col-md-7 d-flex align-items-center justify-content-center">
						<form className="w-100 text-center text-md-start">
							<div className="row">
								<div className="col-12 col-md-8">
									<label className="form-label text-white fw-bold fs-5 fs-lg-4 fs-md-6">Get the latest real estate insights, events and news from our newsletter
										<input type="email" className="form-control" id="radarInputEmail" onChange={(e) => setRadarSubscribeEmail(e.target.value)} placeholder="Input your email address" />
									</label>
								</div>
								<div className="col-md-4 col-sm-4 col-8 m-auto mt-md-auto text-center">
									<a href={mailURL + "&EMAIL=" + radarSubscribeEmail} aria-label="SUBSCRIBE"><div className="btn btn-primary text-secondary fw-bolder fs-1">SUBSCRIBE</div></a>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}