import ReactDOM from 'react-dom/client'
import './_metronic/assets/sass/style.scss'
// import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import { AppRoutes } from './app/routing/AppRoutes'
import { Provider } from 'react-redux'
import Store from './app/Store'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={Store}>
    <AppRoutes />
  </Provider>,
)
