import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    Publications: []
}

const PublicationReducer = createSlice({
    name: 'publication',
    initialState,
    reducers: {
        setPublications: (state, { payload }) => {
            state.Publications = payload
        }
    }
})

export default PublicationReducer