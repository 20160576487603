import { Tab, Tabs } from "react-bootstrap";
import JobGrid from "../../components/About/Jobgrid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

export default function () {
    const url = process.env.REACT_APP_API_URL;

    const meta = {
        title: 'Careers | PRIME Philippines',
        description: 'Join PRIME Philippines and take your career to new heights. Discover exciting opportunities in real estate and property management today.',
        canonical: 'https://www.primephilippines.com/careers',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'PRIME Philippines, careers, real estate, talented professionals, exceptional service, culture, values, benefits, career growth'
            },
            itemProp: {
                name: 'Careers | PRIME Philippines',
                description: 'Join PRIME Philippines and take your career to new heights. Discover exciting opportunities in real estate and property management today.',
                image: 'https://www.primephilippines.com/media/logos/prime-logo.webp'
            },
            property: {
                'og:title': 'Careers | PRIME Philippines',
                'og:description': 'Join PRIME Philippines and take your career to new heights. Discover exciting opportunities in real estate and property management today.',
                'og:image': 'https://www.primephilippines.com/media/logos/prime-logo.webp',
                'og:url': 'https://www.primephilippines.com/careers',
                'og:type': 'website',
                'twitter:title': 'Careers | PRIME Philippines',
                'twitter:description': 'Join PRIME Philippines and take your career to new heights. Discover exciting opportunities in real estate and property management today.',
                'twitter:image': 'https://www.primephilippines.com/media/logos/prime-logo.webp',
                'twitter:url': 'https://www.primephilippines.com/careers',
            }
        },
        auto: {
            ograph: true
        }
    };

    const [internships, setInternships] = useState<any>(null);
    const [jobPostings, setJobPostings] = useState<any>(null);
    const {
        JobLevel,
        Internships,
        JobPostings,
    } = useSelector((state: any) => state.career)
    const dispatch = useDispatch()
    const asyncLoadData = async () => {
        if (JobLevel) {
            setInternships(Internships)
            setJobPostings(JobPostings)
            return
        }
        // Job Level
        const jobLevelResponse = await axios.get(url + "job_level", {
            params: {
                "slug": "internship"
            }
        })
        const job_level = jobLevelResponse.data[0]
        dispatch({ type: 'career/setJobLevel', payload: job_level })

        // Internships
        const internshipsResponse = await axios.get(url + "job_posting", {
            params: {
                "_embed": "wp:featuredmedia,wp:term",
                "job_level": jobLevelResponse.data[0].id,
                "per_page": 100,
            }
        })
        const internships_data = internshipsResponse.data

        dispatch({ type: 'career/setInternships', payload: internships_data })
        setInternships(internships_data)

        // Job Postings
        const jobPostingResponse = await axios.get(url + "job_posting", {
            params: {
                "_embed": "wp:featuredmedia,wp:term",
                "job_level_exclude": jobLevelResponse.data[0].id,
                "per_page": 100,
            }
        })
        const job_postings = jobPostingResponse.data
        dispatch({ type: 'career/setJobPostings', payload: job_postings })
        setJobPostings(job_postings);

        // Recruit
        const recruitResponse = await axios.get(url + "media", {
            params: {
                slug: "recruitment-video",
            }
        })
        const recruit_video_url = recruitResponse.data[0].source_url
        dispatch({ type: 'career/setRecruitVid', payload: recruit_video_url })
    }
    useEffect(() => {
        (async () => await asyncLoadData())()
    }, [])
    return <div>
        <Tabs defaultActiveKey="jobPostings" id="Jobs" className="tab-blue justify-content-start">
            {jobPostings && <Tab eventKey="jobPostings" title="Job Postings">
                <JobGrid object={jobPostings} />
            </Tab>}
            {internships && <Tab eventKey="internship" title="Internships">
                <JobGrid object={internships} />
            </Tab>}
        </Tabs>
    </div>
}