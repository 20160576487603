import {Link} from 'react-router-dom'

export default function ExpertiseNavCard(content: {taglineText?: string, linkStyle?: any, style?: any, image: string, bgColor?: string, titleTextClass?: string, title: string, bodyTextColor?: string, body: string, link: string, closeDrawer : any}) {
	

	return(
		<> 
			<div className="card mb-2 p-5" style={content.style}>
				{/*<img loading="lazy" src={content.image} className="card-img-top overflow-hidden img-fluid" />*/}
				{/*<div className={"card-img-overlay " + content.bgColor + " opacity-50"}></div>*/}
				<p className="fs-6 mb-0">{content.taglineText}</p>
				<h1 className={"card-title " }>
					<Link to={content.link} className={"card-link " + content.titleTextClass} onClick={content.closeDrawer}>{content.title}<i className="fa fa-chevron-right text-primary fs-2 ms-2"></i></Link>
				</h1>
				<p className={"fs-6 mt-n2" + content.bodyTextColor}>{content.body}</p>
				<img loading="lazy" src="/media/divisors/kickstart-bar.webp" className="banner-image" alt="Banner"/>
				{/*<div className={"text-end fw-bold " + content.linkStyle}> CLICK HERE </div>*/}
			</div>
		</>
		)
}