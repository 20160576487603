import { Outlet } from 'react-router-dom'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { FloatingAssistant } from '../components/Misc/FloatingAssistant'
import { RequestCallback } from '../components/Misc/RequestCallback'
import { useSelector } from 'react-redux'
import { useRef } from 'react'

const App = () => {
  const { callback } = useSelector((state: any) => state.floatingAssistant)
  const callbackRef = useRef<any>()
  return (
    <>
      <Outlet />
      <MasterInit />
      {callback && <RequestCallback reference={callbackRef} />}
      <FloatingAssistant reference={callbackRef} />
    </>
  )
}

export { App }
