import { Link } from 'react-router-dom'


export default function NavList(content: any) {

	return (
		<div>
			<p className="h1 text-primary">{content.title}</p>
			<ul className="text-decoration-underline list-unstyled fs-5 lh-lg">
				{
					content.object.map((list: any) => {
						let name = "dummy";
						if (list.hasOwnProperty('title')) {
							name = list.title.rendered;
						}
						else if (list.hasOwnProperty('name')) {
							name = list.name;
						}
						else {
							name = "ERROR";
						}

						if (name == "Uncategorized") {
							return;
						}

						return (
							<li key={list.slug}><Link to={content.from + list.slug} className="text-dark" dangerouslySetInnerHTML={{ __html: name }} onClick={content.closeDrawer}></Link></li>
						)
					}
					)
				}

			</ul>
		</div>)
}