import { Link } from "react-router-dom";


export default function Card(content: any) {
	return (
		<div className={content.class + " "}>
			<Link to={content.link} className={"card-link " + content.titleTextColor}>
				<div className={"card h-100 " + content.cardClass} style={{ height: content.height }}>
					<img loading="lazy" src={content.image} className={"card-img-top " + content.imageClass} alt="" />
					<div className={"card-body pt-8 " + content.bgColor}>
						<h5 className={"card-title " + content.titleTextColor} dangerouslySetInnerHTML={{ __html: content.title }}>
						</h5>
						<h6 className={"card-subtitle " + content.subtitleTextColor}>
							{content.date}
							<span dangerouslySetInnerHTML={{ __html: content.author }}></span>
						</h6>
						<p className={"mt-2 block " + content.bodyClass} dangerouslySetInnerHTML={{ __html: content.body }}></p>
						<div className="text-end fw-bold"> READ MORE <i className="fa fa-chevron-right"></i> </div>
					</div>
				</div>
			</Link>
		</div>
	)
}