import React, { useState, useEffect, useRef } from 'react';
import ExpertiseNav from '../../../../components/NavbarCustom/ExpertiseNav'
import InsightsNav from '../../../../components/NavbarCustom/InsightsNav'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

export default function Subheader() {
	const menu = useRef<HTMLDivElement>(null);
	const exMenu = useRef<HTMLDivElement>(null);
	const location = useLocation();
	useEffect(() => {
		// menu.current?.classList.remove('show')
		const showItems = menu.current?.querySelectorAll('div.show');
		const hoverItems = menu.current?.querySelectorAll('div.hover');
		const activeItems = menu.current?.querySelectorAll('div.acive');
		showItems?.forEach((item) => {
			item.classList.remove("show");
		});
		hoverItems?.forEach((item) => {
			item.classList.remove("hover");
		})
		activeItems?.forEach((item) => {
			item.classList.remove("acive");
		})
		const shItems = exMenu.current?.querySelectorAll('div.show');
		const hovItems = exMenu.current?.querySelectorAll('div.hover');
		const actItems = exMenu.current?.querySelectorAll('div.acive');
		shItems?.forEach((item) => {
			item.classList.remove("show");
		});
		hovItems?.forEach((item) => {
			item.classList.remove("hover");
		})
		actItems?.forEach((item) => {
			item.classList.remove("acive");
		})
	}, [location]);
	return (
		<nav className="navbar navbar-expand-md ms-xl-10 me-xl-10 h-100 py-0 bg-white shadow-bottom">
			<div className="container-fluid d-flex justify-content-between h-100 d-none d-lg-flex px-10">

				<div className="d-flex pe-20 fs-3 bg-white h-100 ms-n3">
					<div className='nav-item ms-10' ref={exMenu}>
						<div
							data-kt-menu-trigger="{default: 'click'}"
							data-kt-menu-attach="parent"
							data-kt-menu-placement="bottom-end"
							role="button"

						>
							<h3 className="h4 text-primary">OUR EXPERTISE <i className="fa fa-angle-down text-primary"></i></h3>
						</div>
						<div
							className='menu menu-sub menu-sub-dropdown menu-column w-800px w-lg-800px h-410px'
							data-kt-menu='true'

						>
							<ExpertiseNav />
						</div>
					</div>

					<div className='app-navbar-item ms-10 me-10' ref={menu}>
						<div
							data-kt-menu-trigger="{default: 'click'}"
							data-kt-menu-attach="parent"
							data-kt-menu-placement="bottom-end"
							role="button"

						>
							<h3 className="h4 text-primary">LATEST INSIGHTS <i className="fa fa-angle-down text-primary"></i></h3>
						</div>
						<div
							className='menu menu-sub menu-sub-dropdown menu-column w-700px w-lg-700px h-410px'
							data-kt-menu='true'

						>
							<InsightsNav />
						</div>
					</div>

					<div className='app-navbar-item'>
						<h3 className="h4 text-primary"><Link to="/find-a-property" className="text-primary">FIND A PROPERTY</Link></h3>
					</div>

				</div>
				<div className="d-sm-0 bg-white h-100 d-md-none d-sm-none d-none d-lg-flex fs-6 h-100 ms-20 me-10">
					<ul className="navbar-nav fw-bold">

						<li className="nav-item dropdown me-3 h4 text-primary">
							<a className="nav-link h4 text-primary" href="#" id="aboutUsDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
								ABOUT US <i className="fa fa-angle-down text-gray-700"></i>
							</a>
							<ul className="dropdown-menu" aria-labelledby="aboutUsDropdown">
								<li className="dropdown-item fs-3"><Link to="/prime-advantage">PRIME Advantage</Link></li>
								<li className="dropdown-item fs-3"><Link to="/leadership">Leadership</Link></li>
								<li className="dropdown-item fs-3"><Link to="/city-enabling">Corporate Sustainability</Link></li>
								<li className="dropdown-item fs-3"><Link to="/awards-and-recognition">Awards &amp; Recognition</Link></li>
							</ul>
						</li>


						<li className="nav-item me-5 h4 text-primary pt-2"><Link to="/careers">CAREERS</Link></li>
						<li className="nav-item me-5 h4 text-primary pt-2"><Link to="/events">EVENTS</Link></li>
						<li className="nav-item me-5 h4 text-primary pt-2"><Link to="/press-room">PRESS ROOM</Link></li>
						<li className="nav-item h4 text-primary pt-2"><Link to="/contact-us">CONTACT US</Link></li>
					</ul>

				</div>

			</div>
		</nav>)

}
