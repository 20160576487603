/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from 'react'
import { ILayout, useLayout } from '../../core'
import RadarFooterBanner from '../../../../components/Footer/RadarFooterBanner'
import { Link } from 'react-router-dom'
import SubFooter from '../../../../components/Footer/SubFooter'

const mailURL = process.env.REACT_APP_MAIL_URL;

const Footer = () => {
  const { config } = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])

  return (
    <>
      <div className=" mx-lg-0 mx-0 my-n3 pt-0 px-md-n5">
        <div className="row d-flex justify-content-center bg-gray-900 mx-xl-10 mx-0 g-0">
          <div className="col-12 mb-5 w-100">
            <RadarFooterBanner />
          </div>
          <div className="col-12 col-xl-7 col-lg-12 col-md-12 col-sm-12 me-n5 mb-5 fs-5 px-10">
            <div className="row g-4">
              <div className="col-12 col-lg-4 col-md-4 col-sm-6 text-dark-muted">
                <h2 className="text-secondary">Our Expertise</h2>
                {/* <p className="text-white">Whatever your real estate service needs may be, our expert team of dedicated professionals will deliver value beyond your expectations.</p> */}

                <div className="d-flex algin-items-center flex-wrap flex-column">
                  <a href="/our-expertise/kickstart" className="text-white me-xl-5 me-5 text-decoration-underline">Kickstart</a>
                  <a href="/our-expertise/seasoned" className="text-white me-xl-5 me-5 text-decoration-underline">Seasoned</a>
                  <a href="/our-expertise/accelerate" className="text-white text-decoration-underline">Accelerate</a>
                </div>

              </div>
              <div className="col-12 col-lg-4 col-md-4 col-sm-6 text-dark-muted">
                <h2 className="text-secondary">Our Services</h2>
                {/* <p className="text-white">Browse our offered available properties. We have lots or spaces that suit your requirements.</p> */}
                <div className="d-flex flex-column">
                  <div>
                    <a href="https://offices.primephilippines.com" className="text-white text-decoration-underline">Office Spaces</a>
                  </div>
                  <div>
                    <a href="https://commercialproperties.primephilippines.com" className="text-white text-decoration-underline">Commercial Spaces and Lots</a>
                  </div>
                  <div>
                    <a href="#" className="text-white text-decoration-underline">Industrial Spaces and Lots</a>
                  </div>
                  <div>
                    <a href="https://davao.primephilippines.com" target="_blank" className="text-white text-decoration-underline">Spaces and Lots in Davao</a>
                  </div>
                </div>

              </div>
              <div className="col-12 col-lg-4 col-md-4 col-sm-6 text-dark-muted">
                <h2 className="text-secondary">About Us</h2>
                <div className="">
                  <div>
                    <a href="/prime-advantage" className="text-white text-decoration-underline">PRIME Advantage</a>
                  </div>
                  <div>
                    <a href="/leadership" className="text-white text-decoration-underline">PRIME Leadership</a>
                  </div>
                  <div>
                    <a href="/city-enabling" className="text-white text-decoration-underline">Corporate Sustainability</a>
                  </div>
                  <div>
                    <a href="/awards-and-recognition" className="text-white text-decoration-underline">Awards and Recognition</a>
                  </div>
                  <div>
                    <a href="/careers" className="text-white text-decoration-underline">Careers at PRIME</a>
                  </div>
                  <div>
                    <a href="/press-room" className="text-white text-decoration-underline">Press Room</a>
                  </div>
                </div>

              </div>
              <div className="col-12 col-lg-4 col-md-4 col-sm-6 text-dark-muted">
                <h2 className="text-secondary">Industry Insights</h2>
                {/* <p className="text-white">Secure your competitive edge with our insights spanning various industries and competencies.</p> */}
                <p className="text-white">By sector:</p>
                <div className="d-flex align-items-center flex-wrap">
                  <Link to="/insights/industrial" className="text-white me-5 text-decoration-underline">Industrial</Link>
                  <Link to="/insights/offices" className="text-white me-5 text-decoration-underline">Office</Link>
                  <Link to="/insights/retail" className="text-white text-decoration-underline">Retail</Link>
                </div>

              </div>
              <div className="col-12 col-lg-4 col-md-4 col-sm-6 text-dark-muted">
                <h2 className="text-secondary">Book a Consultation</h2>
                <p className="text-white">Unsure about what you need? We can help.</p>
                <Link to="/contact-us" className="text-white text-decoration-underline">Book a Consultation</Link><br />

              </div>
              <div className="col-12 col-lg-4 col-md-4 col-sm-6 text-dark-muted">
                <h2 className="text-secondary">Social Media</h2>
                <a href="https://www.facebook.com/PRIMEPhilippines/" target='_blank' className="text-white" aria-label="Facebook"><i className="text-white fs-1 fa-brands fa-facebook me-5"></i></a>
                <a href="https://ph.linkedin.com/company/primephilippines" target='_blank' className="text-white" aria-label="LinkedIn"><i className="text-white fs-1 fa-brands fa-linkedin me-5"></i></a>
                <a href="https://www.youtube.com/@PRIMEPhilippines2013" target='_blank' className="text-white" aria-label="Youtube"><i className="text-white fs-1 fa-brands fa-youtube me-5"></i></a>
                <a href="https://www.instagram.com/primephilippines" target='_blank' className="text-white" aria-label="Instagram"><i className="text-white fs-1 fa-brands fa-instagram me-5"></i></a>
                <a href="https://www.tiktok.com/@prime_philippines" target='_blank' className="text-white" aria-label="Tiktok"><i className="text-white fs-1 fa-brands fa-tiktok me-5"></i></a>

              </div>
            </div>
          </div>
          <div className="col-12 col-xl-5 col-lg-12 col-md-12 col-sm-12 px-2 px-10">
            <div className="row w-100">
              <div className="col-12 col-lg-6 col-md-6">
                <h2 className="text-secondary">Talk to Us</h2>
                <a href="tel:+63284428888" className="text-white text-decoration-underline" aria-label="(+632) 8442 8888">(+632) 8442 8888</a><br />
                <a href="tel:+63276168554" className="text-white text-decoration-underline" aria-label="(+632) 7616 8554">(+632) 7616 8554</a><br />
                <a href="tel:+639175558222" className="text-white text-decoration-underline" aria-label="(+63) 917 555 8222">(+63) 917 555 8222</a><br />
                <p className="text-white">For general inquiries:</p>
                <a href="mailto:inquiries@primephilippines.com" className="word-break text-white text-decoration-underline" aria-label="inquiry@primephilippines.com">inquiry@primephilippines.com</a>
              </div>

              <div className="col-12 col-lg-6 col-md-6 pt-5">
                <h2 className="text-white fs-6 text-secondary">Business Hours</h2>
                <p className="text-white">Mon to Fri 09:00 AM to 06:00 PM </p>
                <h2 className="text-white">Manila</h2>
                <p className="text-white">3/F Main GreatWork Ben- Lor IT Center, Diliman, Quezon City, Metro Manila</p>
                <h2 className="text-white">Davao</h2>
                <p className="text-white">Fourth Floor, Topaz Tower,Damosa I.T. Park, J.P. Laurel Avenue, Lanang,Davao City, Davao Del Sur, Philippines 8000</p>
                <h2 className="text-white">Cebu</h2>
                <p className="text-white">Fifth Floor, Park Centrale, Cebu I.T. Park Jose Maria del Mar Street, Cebu City 6000 Cebu</p>
              </div>
            </div>
          </div>
          <SubFooter />
        </div>
      </div>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export { Footer }
