import { Link } from 'react-router-dom';
import ExpertiseNav from "../../../../components/NavbarCustom/ExpertiseNav"
import InsightsNav from '../../../../components/NavbarCustom/InsightsNav'


export default function Offcanvas() {
  const closeDrawer = () => {
    DrawerComponent.hideAll();
  };
  return (
    <div className="accordion w-100">
      <div className="accordion-item w-100">
        <h3 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#expertiseDropdown" aria-expanded="false"><h3>OUR EXPERTISE</h3></button>
        </h3>
        <div id="expertiseDropdown" className="accordion-collapse collapse">
          <div className="accordion-body">
            <ExpertiseNav closeDrawer={closeDrawer} />
          </div>
        </div>
      </div>

      <div className="accordion-item w-100">
        <h3 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#insightDropdown" aria-expanded="false"><h3>LATEST INSIGHTS</h3></button>
        </h3>
        <div id="insightDropdown" className="collapse show">
          <div className="accordion-body">
            <InsightsNav closeDrawer={closeDrawer} />
          </div>
        </div>
      </div>

      <div className="accordion-item w-100">
        <h3 className="accordion-header">
          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#aboutDropdown" aria-expanded="true"><h3>ABOUT US</h3></button>
        </h3>
        <div id="aboutDropdown" className="accordion-collapse show">
          <div className="accordion-body bg-gray-100 pb-1 lh-1">

            <Link to="/prime-advantage" onClick={closeDrawer}><p className="ms-10 fs-2 text-black">PRIME Advantage</p></Link>
            <Link to="/leadership" onClick={closeDrawer}><p className="ms-10 fs-2 text-black">Leadership</p></Link>
            <Link to="/city-enabling" onClick={closeDrawer}><p className="ms-10 fs-2 text-black">Corporate Sustainability</p></Link>
            <Link to="/awards-and-recognition" onClick={closeDrawer}><p className="ms-10 fs-2 text-black">Awards &amp; Recognition</p></Link>

          </div>
        </div>
      </div>
      <Link to="/find-a-property" onClick={closeDrawer}><h3 className="m-5">FIND A PROPERTY</h3></Link>
      <Link to="/careers" onClick={closeDrawer}><h3 className="m-5">CAREERS</h3></Link>
      <Link to="/events" onClick={closeDrawer}><h3 className="m-5">EVENTS</h3></Link>
      <Link to="/press-room" onClick={closeDrawer}><h3 className="m-5">PRESS ROOM</h3></Link>
      <Link to="/contact-us" onClick={closeDrawer}><h3 className="m-5">CONTACT US</h3></Link>
    </div>

  )
}

