import { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import countryCodes from '../../assets/country_code.json'
import {
    AiOutlineClose
} from 'react-icons/ai'
import anime from "animejs"
import axios from "axios"
import ReCAPTCHA from "react-google-recaptcha"
const RequestCallback = ({ reference }: any) => {
    const apiUrl = process.env.REACT_APP_PRIMECORE_API_URL
    const site_key = process.env.REACT_APP_RECAPTCHA_KEY;

    const { callback } = useSelector((state: any) => state.floatingAssistant)
    const captchaRef = useRef(null)
    const [captchaValue, setCaptchaValue] = useState<any>()
    const callbackRef = useRef<any>()
    type field = {
        value: string,
        pattern: RegExp,
        error: boolean
    }
    const property_type = useRef<any>()
    const firstname = useRef<any>()
    const lastname = useRef<any>()
    const phone = useRef<any>()
    const email = useRef<any>()
    const agreement = useRef<any>()

    const dispatch = useDispatch()
    const [formData, setFormData] = useState({
        property_type: {
            value: '',
            pattern: /^(.{1,})+$/,
            error: false,
            ref: property_type
        },
        firstname: {
            value: '',
            pattern: /^([a-zA-Z\s]{1,})+$/,
            error: false,
            ref: firstname
        },
        lastname: {
            value: '',
            pattern: /^([a-zA-Z\s]{1,})+$/,
            error: false,
            ref: lastname
        },
        phone: {
            value: '',
            pattern: /^\+(?:\d{1,3})?\d{10}$/,
            error: false,
            ref: phone
        },
        email: {
            value: '',
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            error: false,
            ref: email
        },
        agreement: {
            value: 'false',
            pattern: /^(true)+$/,
            error: false,
            ref: agreement
        },
    })
    const [country_code, setCountryCode] = useState('+63')
    const [country, setCountry] = useState('Philippines')
    const formInputs = [
        {
            type: "text",
            placeholder: 'What are you interested in?',
            property_name: "property_type",
            render: () => {
                const handleChange = (event: any) => {
                    const { value } = event.target
                    const { pattern, error }: field = formData['property_type']
                    setFormData((prev: any) => {
                        return {
                            ...prev,
                            property_type: {
                                ...prev.property_type,
                                value: value,
                            }
                        }
                    })
                    if (pattern.test(value)) {
                        setFormData((prev: any) => {
                            return {
                                ...prev,
                                property_type: {
                                    ...prev.property_type,
                                    error: false
                                }
                            }
                        })
                        return
                    } else {
                        setFormData((prev: any) => {
                            return {
                                ...prev,
                                property_type: {
                                    ...prev.property_type,
                                    error: true
                                }
                            }
                        })
                        return
                    }
                }
                const property_types = [
                    'Office Spaces',
                    'Commercial Lots',
                    'Retail Spaces',
                    'Industrial Lots',
                    'Industrial Warehouse'
                ]
                return (
                    <div className="d-flex align-items-center input-group">
                        <select ref={property_type} className={`form-control border ${formData.property_type.error ? 'border-danger' : 'border-primary'}`} autoFocus defaultValue={formData.property_type.value} onChange={handleChange}>
                            <option value="">-- Choose Property Type --</option>
                            {property_types.map((property_type, key) => (
                                <option key={key} value={property_type}>{property_type}</option>
                            ))}
                        </select>
                    </div>
                )
            }
        },
        {
            type: "text",
            placeholder: 'First Name',
            property_name: "firstname",
            render: () => {
                const handleChange = (event: any) => {
                    const { value } = event.target
                    const { pattern, error }: field = formData['firstname']
                    setFormData((prev: any) => {
                        return {
                            ...prev,
                            firstname: {
                                ...prev.firstname,
                                value: value,
                            }
                        }
                    })
                    if (pattern.test(value)) {
                        setFormData((prev: any) => {
                            return {
                                ...prev,
                                firstname: {
                                    ...prev.firstname,
                                    error: false
                                }
                            }
                        })
                        return
                    } else {
                        setFormData((prev: any) => {
                            return {
                                ...prev,
                                firstname: {
                                    ...prev.firstname,
                                    error: true
                                }
                            }
                        })
                        return
                    }
                }
                return (
                    <div className="input-group">
                        <input ref={firstname} type="text" className={`form-control border ${formData.firstname.error ? 'border-danger' : 'border-primary'}`} placeholder="e.g. John Paul" onChange={handleChange} />
                    </div>
                )
            }
        },
        {
            type: "text",
            placeholder: 'Last Name',
            property_name: "lastname",
            render: () => {
                const handleChange = (event: any) => {
                    const { value } = event.target
                    const { pattern, error }: field = formData['lastname']
                    setFormData((prev: any) => {
                        return {
                            ...prev,
                            lastname: {
                                ...prev.lastname,
                                value: value,
                            }
                        }
                    })
                    if (pattern.test(value)) {
                        setFormData((prev: any) => {
                            return {
                                ...prev,
                                lastname: {
                                    ...prev.lastname,
                                    error: false
                                }
                            }
                        })
                        return
                    } else {
                        setFormData((prev: any) => {
                            return {
                                ...prev,
                                lastname: {
                                    ...prev.lastname,
                                    error: true
                                }
                            }
                        })
                        return
                    }
                }
                return (
                    <div className="input-group">
                        <input type="text" ref={lastname} className={`form-control border ${formData.lastname.error ? 'border-danger' : 'border-primary'}`} placeholder="e.g. Doe Rex" onChange={handleChange} />
                    </div>
                )
            }
        },
        {
            type: "text",
            placeholder: 'Email',
            property_name: "email",
            render: () => {
                const handleChange = (event: any) => {
                    const { value } = event.target
                    const { pattern, error }: field = formData['email']
                    setFormData((prev: any) => {
                        return {
                            ...prev,
                            email: {
                                ...prev.email,
                                value: value,
                            }
                        }
                    })
                    if (pattern.test(value)) {
                        setFormData((prev: any) => {
                            return {
                                ...prev,
                                email: {
                                    ...prev.email,
                                    error: false
                                }
                            }
                        })
                        return
                    } else {
                        setFormData((prev: any) => {
                            return {
                                ...prev,
                                email: {
                                    ...prev.email,
                                    error: true
                                }
                            }
                        })
                        return
                    }
                }
                return (
                    <div className="input-group">
                        <input type="email" ref={email} className={`form-control border ${formData.email.error ? 'border-danger' : 'border-primary'}`} placeholder="e.g. johndoe@example.email" onChange={handleChange} />
                    </div>
                )
            }
        },
        {
            type: "text",
            placeholder: 'Phone',
            property_name: "phone",
            render: () => {
                const handleChange = (event: any) => {
                    const { value } = event.target
                    const { pattern, error }: field = formData['phone']
                    const phone = country_code + value
                    setFormData((prev: any) => {
                        return {
                            ...prev,
                            phone: {
                                ...prev.phone,
                                value: phone
                            }
                        }
                    })
                    if (pattern.test(phone)) {
                        setFormData((prev: any) => {
                            return {
                                ...prev,
                                phone: {
                                    ...prev.phone,
                                    error: false
                                }
                            }
                        })
                        return
                    } else {
                        setFormData((prev: any) => {
                            return {
                                ...prev,
                                phone: {
                                    ...prev.phone,
                                    error: true
                                }
                            }
                        })
                        return
                    }
                }
                return (
                    <div className="d-flex align-items-center input-group gap-2">
                        <select required className="form-control" defaultValue={country_code} autoFocus onChange={(event) => {
                            const { value } = event.target
                            const option_selected = event.target.options[event.target.selectedIndex]
                            const attr_value: any = option_selected.getAttribute('data-info')
                            const info = JSON.parse(attr_value)

                            setCountry(info.name)
                            setCountryCode(info.phone[0])
                        }}>
                            {Object.entries(countryCodes).map(([country, info], key) => (
                                <option key={key} data-info={JSON.stringify(info)} value={info.phone[0]}>{info.emoji} {info.name} ({info.phone[0]})</option>
                            ))}
                        </select>
                        <input type="text" ref={phone} className={`form-control border ${formData.phone.error ? 'border-danger' : 'border-primary'}`} placeholder="e.g. 9123456789" onChange={handleChange} />
                    </div>
                )
            }
        },
        {
            type: "text",
            placeholder: 'Agreement',
            property_name: "agreement",
            render: () => {
                const handleChange = (event: any) => {
                    const { value } = event.target
                    const { pattern, error }: field = formData['agreement']
                    setFormData((prev: any) => {
                        return {
                            ...prev,
                            agreement: {
                                ...prev.agreement,
                                value: value
                            }
                        }
                    })
                    if (pattern.test(value)) {
                        setFormData((prev: any) => {
                            return {
                                ...prev,
                                agreement: {
                                    ...prev.agreement,
                                    error: false
                                }
                            }
                        })
                        return
                    } else {
                        setFormData((prev: any) => {
                            return {
                                ...prev,
                                agreement: {
                                    ...prev.agreement,
                                    error: true
                                }
                            }
                        })
                        return
                    }
                }
                return (
                    <div className="form-check mt-2 mb-4">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={formData.agreement.value === "true" ? true : false}
                            defaultValue={formData.agreement.value === "false" ? "false" : "true"}
                            onChange={handleChange}
                            value={formData.agreement.value === "true" ? "false" : "true"}
                            id="flexCheckDefault" />
                        <label
                            className="form-check-label"
                            style={{ userSelect: 'none' }}
                            htmlFor="flexCheckDefault">I would like Prime Philippines to send me offers and communications.</label>
                    </div>
                )
            }
        },
    ]

    const handleClose = () => {
        anime({
            targets: callbackRef.current,
            opacity: 0,
            duration: 500,
            easing: 'linear'
        })
        dispatch({ type: 'floatingAssistant/toggleCallback', payload: false })
    }
    const validate = () => {
        // Validate all fields
        let valid = true
        Object.entries(formData).map(([key, { pattern, value, ref }]) => {
            if (!pattern.test(value)) {
                if (ref.current) {
                    ref.current.focus()
                    ref.current.classList.add("border-danger")
                }
                setFormData((prev: any) => {
                    return {
                        ...prev,
                        [key]: {
                            ...prev[key],
                            error: true
                        }
                    }
                })
                valid = false
                return
            }
        })
        return valid
    }
    const handleSubmit = async (e: any) => {
        try {
            e.preventDefault()
            if (!validate()) {
                return
            }
            if (!captchaRef.current?.getValue()) {
                alert('Please verify recaptcha')
            }
            const agreement = formData.agreement.value === "true"
            const data = {
                property_type: formData.property_type.value,
                firstname: formData.firstname.value,
                lastname: formData.lastname.value,
                phone: formData.phone.value,
                email: formData.email.value,
                country: country,
                website_inquiry: 'any',
            }
            if (!agreement) return
            const response = await axios.post(`${apiUrl}website_inquiry`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (response.data.status === 200) {
                dispatch({ type: 'floatingAssistant/toggleCallback', payload: false })
                captchaRef.current?.reset()
            }
        } catch (error: any) {
            console.log(error.message)

        }
    }
    const handleRecaptcha = (value: any) => {
        setCaptchaValue(value)
    }
    return (
        <div ref={reference} className={`position-fixed container-fluid d-flex align-items-center justify-content-center h-100`} style={{
            width: '100%',
            top: 0,
            left: 0,
            zIndex: 9999,
            backgroundColor: 'rgba(0,0,0,0.7)'
        }}>
            <div className="card p-4 col-md-6 col-sm-8 rounded-2">
                <div className="d-flex flex-end">
                    <AiOutlineClose
                        size={20}
                        cursor={"pointer"}
                        onClick={handleClose}
                    />
                </div>
                <div className="card card-title">
                    <div className="fs-1 fw-bold p-4">Talk to an Expert</div>
                </div>
                <form className="d-flex flex-column gap-2 px-4" onSubmit={(e) => handleSubmit(e)}>
                    {
                        formInputs.map((form: any, key: any) => (
                            <div key={key}>
                                <div className="fs-4">{form.placeholder} <span className="text-danger">*</span></div>
                                {form.render()}
                            </div>
                        ))
                    }
                    < ReCAPTCHA
                        ref={captchaRef}
                        sitekey={site_key!}
                        onChange={handleRecaptcha}
                        className="py-4"
                    />
                    <input type="submit" className="btn btn-primary w-100 rounded" value="Submit" />
                </form>
            </div>
        </div>
    )
}
export { RequestCallback }