import { Routes, Route, BrowserRouter, Navigate, useLocation } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { App } from '../App'
import { useLayoutEffect } from 'react'
const Wrapper = ({ children }: any) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}
const AppRoutes = () => {

  return (
    <BrowserRouter>
      <Wrapper>
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='/*' element={<PrivateRoutes />} />
          </Route>
        </Routes>
      </Wrapper>
    </BrowserRouter>
  )
}

export { AppRoutes }
